import moment from "moment";
export default class order {
  constructor() {
    this.input = {
      cityId: null,
      couriers: null,
      deleted: false,
      departmentIds: [],
      pageSize: 15,
      page: 0,
      pickers: [],
      startTime: null,
      endTime: null,
      statuses: [],
      onlyPreOrders: false,
      sorted: "",
    };
    this.body = [];
    this.preInput = {
      courierId: null,
      departmentId: null,
      pickerId: null,
      statusId: null,
    };
  }
  setInput(city) {
    this.input.cityId = city;
    this.input.startTime = moment().format("YYYY-MM-DD");
    this.input.endTime = moment().format("YYYY-MM-DD");
    return this.input;
  }
  setPreInput() {
    return this.preInput;
  }
}
