<template>
  <div class="order-status_wrapper">
    <div class="order-status_left">
      <div class="order-block pa-1">
        <ul v-if="expectedDelivery" class="order-status_expected" type="circle">
          <li class="order-status_exp-item">
            <v-icon color="black" class="order-status_exp-icon"
              >mdi-circle-small</v-icon
            >
            <span class="black--text">Ожидаемое время сборки:</span>
            <span>{{
              getTime(expectedDelivery.inProcessStatusExpectedTime)
            }}</span>
          </li>
          <li class="order-status_exp-item">
            <v-icon color="black" class="order-status_exp-icon"
              >mdi-circle-small</v-icon
            >
            <span class="black--text">Ожидаемое время на кассе:</span>
            <span>{{
              getTime(expectedDelivery.cashDeskStatusExpectedTime)
            }}</span>
          </li>
          <li class="order-status_exp-item">
            <v-icon color="black" absolute class="order-status_exp-icon"
              >mdi-circle-small</v-icon
            >
            <span class="black--text">Ожидаемое время доставки заказа:</span>

            <span>{{ getTime(expectedDelivery.deliveredExpectedTime) }}</span>
          </li>
          <li class="order-status_exp-item">
            <v-icon color="black" class="order-status_exp-icon"
              >mdi-circle-small</v-icon
            >
            <span class="black--text"
              >Действительное время заказа на кассе:</span
            >
            <span>{{ getTime(expectedDelivery.cashDeskStatusRealTime) }}</span>
          </li>
          <li class="order-status_exp-item">
            <v-icon color="black" class="order-status_exp-icon"
              >mdi-circle-small</v-icon
            >
            <span class="black--text"
              >Перерасчет ожидаемого времени доставки:</span
            >
            <span>{{
              getTime(expectedDelivery.deliveredRecalculateTime)
            }}</span>
          </li>
          <li class="order-status_exp-item">
            <v-icon color="black" class="order-status_exp-icon"
              >mdi-circle-small</v-icon
            >
            <span class="black--text">Действительное время доставки:</span>
            <span>{{ getTime(expectedDelivery.deliveredRealTime) }}</span>
          </li>
        </ul>
      </div>
      <div class="order-block pa-1">
        <!-- История статусов  -->
        <div class="order-status_title">Процесс комплектования заказа</div>
        <v-simple-table light class="order-status_table">
          <thead>
            <tr>
              <th class="order-status__black">Статус заказа</th>
              <th class="order-status__black">Кем изменен</th>
              <th class="order-status__black">Время</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in statusChange" :key="item.id">
              <td class="order-status__status-title">
                <div
                  :style="
                    'border-radius: 6px; font-size: 12px; background-color: ' +
                    getColor(item.status.title)
                  "
                  class="pa-2"
                >
                  {{ item.status.title }}
                </div>
              </td>
              <td class="order-status__status-item">
                {{ getChanger(item) }}
              </td>
              <td class="order-status__status-item">
                {{ getTime(item.createdAt) }}
                {{ "(" + getDiff(item.createdAt, index) + " минут" + ")" }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <div class="order-block pa-1">
        <!-- История статусов оплаты -->
        <div class="order-status_title">Процесс оплаты заказа</div>
        <v-simple-table light class="order-status_table">
          <thead>
            <tr>
              <th class="order-status__black">Статус оплаты</th>
              <th class="order-status__black">Кем изменен</th>
              <th class="order-status__black">Время</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paymentHistory" :key="item.id">
              <td class="order-status__status-title">
                <div
                  :style="
                    'border-radius: 6px; font-size: 12px; background-color: ' +
                    getColor(item.paymentStatus.id)
                  "
                  class="pa-2"
                >
                  {{ item.paymentStatus.name }}
                </div>
              </td>
              <td class="order-status__status-item">
                {{ item.user !== null ? item.user.username : "Система" }}
              </td>
              <td class="order-status__status-item">
                {{ getTime(item.createdAt) }}
                {{
                  "(" + getDiff(item.createdAt, index, true) + " минут" + ")"
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table
          v-if="employeeChange.length > 0"
          light
          style="max-width: 100%"
        >
          <thead>
            <tr>
              <th class="order-status__black">Сотрудник</th>
              <th class="order-status__black">Кем назначен</th>
              <th class="order-status__black">Время</th>
            </tr>
            <tr v-for="item in employeeChange" :key="item.id">
              <td v-if="item.employee">
                {{ item.employee.username + ", " + item.employee.role.name }}
              </td>
              <td v-if="item.admin">
                {{ item.admin.username + ", " + item.admin.role.name }}
              </td>
              <td>
                {{ getTime(item.createdAt) }}
              </td>
            </tr>
          </thead>
        </v-simple-table>
      </div>
    </div>
    <delivery-status
      :order-id="orderId"
      :statuses="deliveryStatuses"
    ></delivery-status>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeliveryStatus from "@/components/Order/DeliveryStatus";
import moment from "moment";
export default {
  components: {
    DeliveryStatus,
  },
  props: {
    statuses: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusChange: [],
      employeeChange: [],
      paymentHistory: [],
      deliveryStatuses: [],
      expectedDelivery: null,
      orderId: null,
    };
  },
  computed: {
    ...mapGetters({
      ORDER: "Order/STATE",
      STATUS: "Status/STATE",
    }),
  },
  mounted() {
    this.setAll();
  },
  methods: {
    ...mapActions({
      ORDER_HISTORY: "Order/ORDER_HISTORY",
      ORDER_PAY_HISTORY: "Order/ORDER_PAYMENT_HISTORY",
      GET_ALL_STATUS: "Status/GET_ALL_STATUS",
      ORDER_EMPLOYEE_HISTORY: "Order/ORDER_EMPLOYEE_HISTORY",
    }),
    async setAll() {
      this.loading = true;
      this.orderId = this.statuses.providerOrder.id;
      this.setStatus(this.statuses.providerLogOrderStatuses);
      this.paymentHistory = this.statuses.providerLogPaymentStatuses;
      this.employeeChange = this.statuses.providerOrderEmployeeLogs;
      this.expectedDelivery = this.statuses.orderPackDeliveryLogDto;
      this.loading = false;
    },
    async setStatus(allStatuses) {
      this.statusChange = allStatuses.filter((item) => !item.status.isYandex);
      this.deliveryStatuses = allStatuses.filter(
        (item) => item.status.isYandex
      );
    },
    getDiff(a, b, payment) {
      let c;
      if (payment) {
        if (b >= this.paymentHistory.length - 1) {
          c = moment();
        } else {
          c = this.paymentHistory[b + 1].createdAt;
        }
      } else {
        if (b >= this.statusChange.length - 1) {
          c = moment();
        } else {
          c = this.statusChange[b + 1].createdAt;
        }
      }

      return moment(c).diff(a, "minutes");
    },
    getTime(time) {
      if (!time) {
        return "Не указано";
      }
      moment.locale("ru");
      return moment(time).format("DD/MM/YYYY HH:mm");
    },
    getChanger(item) {
      // По умолчанию система
      let changer = "Система";
      // если указан пользователь, то это админ
      if (item.user) {
        // если статус отменен, то добавляется тег(причина) отмены
        if (item.status.id === 9) {
          changer = `${item.user.username} (${item.cancelOrderTag?.name})`;
        } else {
          changer = item.user.username;
        }
      }
      // если указан клиент, то это клиент)
      if (item.client) {
        // если статус отменен, то добавляется тег(причина) отмены
        if (item.status.id === 9) {
          changer = `Клиент : ${item.client.name} ${item.client.surname} (${item.clientCancelOrderTag?.name})`;
        } else {
          changer = `${item.client.name} ${item.client.surname}`;
        }
      }
      return changer;
    },
    getColor(x) {
      switch (x) {
        case "Новый":
          return "rgb(208, 202, 2)";
        case 3:
          return "rgb(208, 202, 2)";
        case "В обработке":
          return "rgb(226, 172, 84)";
        case 2:
          return "rgb(226, 172, 84)";
        case "Готов к согласованию":
          return "rgb(133, 197, 224)";
        case 1:
          return "rgb(133, 197, 224)";
        case "Согласован":
          return "rgb(223, 137, 86)";
        case "На кассе":
          return "rgb(104, 106, 105)";
        case "Отгружен":
          return "rgb(58, 159, 201)";
        case "Доставляется":
          return "rgb(251, 255, 0)";
        case "Доставлен":
          return "rgb(36, 150, 65)";
        default:
          return "rgb(146, 149, 148)";
      }
    },
  },
};
</script>

<style lang="scss">
.order-status {
  &_title {
    font-weight: 600;
    color: black;
    margin-left: 5px;
  }
  &__status-title {
    color: white;
    font-weight: 600;
    width: 20%;
    @media screen and (max-width: 900px) {
      & {
        width: 100px;
      }
    }
  }
  &__status-item {
    width: 33%;
  }
  &__black {
    color: black !important;
  }
  &_wrapper {
    display: flex;
    gap: 5px;
    width: 100%;
    @media screen and (max-width: 960px) {
      flex-direction: column;
      font-size: 0.7rem;
    }
  }
  &_left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
  &_expected {
    width: max-content;
  }
  &_exp-icon {
    position: absolute !important;
    left: -20px;
  }
  &_exp-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    @media screen and (max-width: 470px) {
      flex-direction: column;
    }
  }
  &_table {
    max-height: 300px;
    overflow-y: auto;
    max-width: 100%;
    width: 100%;
  }
}
</style>
