<template>
  <v-autocomplete
    v-model="input[inputKey]"
    :items="items"
    :item-text="itemsName"
    item-value="id"
    multiple
    small-chips
    class="order-auto"
    @change="changeInput()"
  >
    <template v-slot:append>
      <v-icon color="white">mdi-menu-down</v-icon>
    </template>
    <template slot="label">
      <span class="white--text">{{ name }}</span>
    </template>
    <template v-slot:selection="data">
      <v-chip x-small>
        {{ data.item[itemsName] }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "AutoComplete",
  props: {
    input: {
      type: Object,
      default: null,
    },
    inputKey: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsName: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      debounceId: 0,
    };
  },
  methods: {
    changeInput() {
      if (this.debounceId) {
        clearTimeout(this.debounceId);
      }
      this.debounceId = setTimeout(() => {
        this.$emit("change");
        clearTimeout(this.debounceId);
      }, 1500);
    },
  },
};
</script>
