<template>
  <div class="delivery-status__wrapper">
    <div class="order-block pa-1 height">
      <h3 class="delivery-status_header">Интеграция с курьерскими службами</h3>
      <div class="delivery-status_code">
        <h4>
          <span class="delivery-status_code__black">Yandex</span> доставка
        </h4>
        <p class="ma-0">
          Код подтверждения:
          <span class="delivery-status_code__black">{{
            code || "Нет данных"
          }}</span>
        </p>
      </div>
      <v-simple-table v-if="statuses.length > 0" light style="max-width: 100%">
        <thead>
          <tr>
            <th class="delivery-status_th">Курьер</th>
            <th class="delivery-status_th">Номер телефона</th>
            <th class="delivery-status_th">Статус курьера</th>
            <th class="delivery-status_th">Время</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in statuses" :key="item.id">
            <td>
              {{ item.user.username || "Курьер Яндекс" }}
            </td>
            <td>
              {{ phone ? phone : "Нет данных" }}
            </td>
            <td>
              {{ item.status.name }}
            </td>
            <td>
              {{
                item.createdAt.substr(0, 10) +
                ", " +
                item.createdAt.substr(11, 5)
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "DeliveryStatus",
  props: {
    orderId: {
      type: Number,
      default: null,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      phone: null,
      code: null,
    };
  },
  watch: {
    async statuses(val) {
      if (val.length > 0) {
        this.getYandexCourierInfo();
      }
    },
  },
  methods: {
    async getYandexCourierInfo() {
      const respPhone = await api.DeliveryStatus.getYandexCourierPhone(
        this.orderId
      );
      this.phone = respPhone.phone;
      const respCode = await api.DeliveryStatus.getYandexCourierCode(
        this.orderId
      );
      if (respCode.type !== "error") {
        this.code = respCode?.code;
      }
    },
  },
};
</script>

<style lang="scss">
.delivery-status {
  &_header {
    text-align: center;
    margin-top: 10px;
  }
  &__wrapper {
    width: 50%;
    min-height: 100%;
    @media screen and (max-width: 960px) {
      font-size: 0.7rem;
      width: 100%;
    }
  }
  &_code {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    &__black {
      margin: 0 !important;
      color: black;
      font-weight: 600;
    }
  }
  &_th {
    color: black !important;
  }
}
.height {
  min-height: 100%;
}
</style>
