import Stomp from "webstomp-client";

function playAudio() {
  const audio = new Audio(require("@/assets/Sound.mp3"));
  audio.play();
}
let socket = null;
let stompClient = null;

// все функции сокетов работают в этом файле, контекст передается аргументом that
export default function websocket(that) {
  if (stompClient) {
    stompClient.disconnect();
  }
  socket = new WebSocket(process.env.VUE_APP_WS_URL);
  stompClient = Stomp.over(socket);
  stompClient.connect(
    {},
    (frame) => {
      const t = frame;
      if (t.command == true) {
        return;
      }
      websocketCallback(that);
    },
    () => {
      reconnectWebsocket(that);
    }
  );
}
async function reconnectWebsocket(that) {
  let connected = false;
  let connectionInterval = setInterval(() => {
    socket = new WebSocket(process.env.VUE_APP_WS_URL);
    stompClient = Stomp.over(socket);
    stompClient.connect(
      {},
      (frame) => {
        clearInterval(connectionInterval);
        connected = true;
        const t = frame;
        if (t.command == true) {
          return;
        }
        websocketCallback(that);
      },
      () => {
        if (connected) {
          reconnectWebsocket(that);
        }
      }
    );
  }, 30000);
}
function websocketCallback(that) {
  // добавление заказа
  stompClient.subscribe(
    `/admin/socket/provider/order/add/2/${that.$root.city}`,
    (data) => {
      const sock = JSON.parse(data.body);
      const providerId = that.$root.isProvider;
      // если поставщик а не суперадмин проверяем его ли это заказ
      if (providerId) {
        const forThisProvider = that.USER.userInfo.departments.some(
          (dep) => dep.id === sock.order.department
        );
        if (!forThisProvider) {
          return;
        }
      }
      if (
        that.orders.findIndex((el) => el.orderId === sock.order.orderId) === -1
      ) {
        if (that.filter === 1) {
          let departmentOrderFilter = false;
          let statusOrderFilter = false;
          if (
            that.input.statuses &&
            that.input.statuses.includes(sock.order.status)
          ) {
            statusOrderFilter = true;
          }
          if (that.input.statuses.length === 0) {
            statusOrderFilter = true;
          }
          if (
            that.input.departmentIds.length &&
            that.input.departmentIds.includes(sock.order.department)
          ) {
            departmentOrderFilter = true;
          }
          if (that.input.departmentIds.length === 0) {
            departmentOrderFilter = true;
          }
          // добавление нового заказа вверх очереди
          if (departmentOrderFilter && statusOrderFilter) {
            that.orders.unshift(sock.order);
            that.setStat();
            playAudio();
          }
        }
      }
    }
  );
  // изменение заказов
  stompClient.subscribe("/admin/socket/provider/order/edit/2", (data) => {
    const sock = JSON.parse(data.body);
    let orderIndex = that.orders.findIndex(
      (el) => el.orderId === sock.order.orderId
    );
    if (orderIndex === -1) {
      return;
    }
    that.orders.splice(orderIndex, 1, sock.order);
    that.setStat();
  });
  // удаление заказа
  stompClient.subscribe("/admin/socket/provider/order/delete/2", (data) => {
    const sock = JSON.parse(data.body);
    let orderIndex = that.orders.findIndex(
      (val) => val.orderId === sock.order.orderId
    );
    if (orderIndex === -1) {
      return;
    }
    that.orders.splice(orderIndex, 1);
    that.setStat();
  });
  // выход работника со смены
  stompClient.subscribe(`/admin/socket/employee/shift/open/2`, (data) => {
    const sock = JSON.parse(data.body);
    if (sock.employee.role.name === "Курьер") {
      that.courier.push({
        id: sock.employee.id,
        username: sock.employee.username,
        departmentId: sock.shift.department.id,
      });
    } else {
      that.picker.push({
        id: sock.employee.id,
        username: sock.employee.username,
        departmentId: sock.shift.departmentId,
      });
    }
  });
  // уход работника со смены
  stompClient.subscribe(`/admin/socket/employee/shift/close/2`, (data) => {
    const sock = JSON.parse(data.body);
    let empId;
    if (sock.employee.role.name === "Курьер") {
      empId = that.courier.findIndex((el) => el.id === sock.employee.id);
      that.courier.splice(1, empId);
    } else {
      empId = that.picker.findIndex((el) => el.id === sock.employee.id);
      that.picker.splice(1, empId);
    }
  });
  // изменение статуса, не срабатывает, при изменении статуса срабатывает edit
  stompClient.subscribe(
    "/admin/socket/provider/order/status/update/2",
    (data) => {
      const sock = JSON.parse(data.body);
      let orderIndex = that.orders.findIndex(
        (el) => el.orderId === sock.order.orderId
      );
      if (orderIndex === -1) {
        return;
      }
      that.orders.splice(orderIndex, 1, sock.order);
      that.setStat();
    }
  );
  // изменение курьера, не срабатывает, при изменении курьера срабатывает edit
  stompClient.subscribe(`/admin/socket/order/courier/add/2`, (data) => {
    const sock = JSON.parse(data.body);
    let orderIndex = that.orders.findIndex(
      (el) => el.orderId === sock.order.orderId
    );
    if (orderIndex === -1) {
      return;
    }
    that.orders.splice(orderIndex, 1, sock.order);
  });
}
function closeWebsocket() {
  stompClient.disconnect();
}
